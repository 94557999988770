$label-margin-bottom: 0rem;
$body-color: #000;
$progress-height: 4px;
$progress-border-radius: 0;
$modal-content-border-width: 0;
$line-height-base: 1.2;
$success: #3dc952;
$danger: #cb0c16;
$input-box-shadow: none;
$input-focus-width: 0;
$input-btn-focus-width: 0;
$grid-gutter-width: 15px;
$label-margin-bottom: 5px;
$tooltip-bg: #bababa;
$tooltip-color: #000;
$tooltip-font-size: 0.8em;
$tooltip-max-width: 300px;

$input-btn-padding-y: 0.587rem;
$light-gray: #ebebeb;

@import '~bootstrap/scss/bootstrap';

$primaryOpacity: rgba($primary, 0.1);
$dangerOpacity: rgba($danger, 0.1);
$halfGutter: $grid-gutter-width / 2;

$input-active-border-color: #333333;
$input-inactive-border-color: #e3e3e3;
$input-error-border-color: #d91a2a;
$input-success-border-color: #3cc850;

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    color 0.15s ease-in-out;

html {
    font-size: 14px;
}

body {
    padding-bottom: $halfGutter;
    min-width: 320px;
    padding: 0;
    margin: 0;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
input[type='textarea']:focus,
textarea:focus,
select,
select:focus,
.btn,
option,
#notRollingDescription {
    font-size: 16px !important;
}

@include media-breakpoint-down(xs) {
    .label-year {
        display: none;
    }
}

.btn-group {
    display: flex;
    width: 100%;
    .btn {
        flex: 1;
        font-size: 14px;
    }
}

.makes-logo {
    margin-bottom: 1rem;

    .fivecol-container {
        .fivecol {
            width: 20%;
            position: relative;
            padding-right: $halfGutter;
            padding-left: $halfGutter;
        }

        // removing the two last logo for mobile
        @include media-breakpoint-down(xs) {
            .fivecol {
                width: 25%;
            }

            .fivecol:nth-last-child(1),
            .fivecol:nth-last-child(2) {
                display: none !important;
                width: 1px;
            }
        }
        // ----
    }

    .make-logo-button-container {
        text-align: center;
        font-size: 11px;
        margin-bottom: 10px;
        div {
            border: 1px solid #ccc;
            border-radius: 2px;
            display: block;
            margin-bottom: 0.5rem;
            &.selected {
                border: 1px solid $primary;
            }
            img {
                width: 100%;
                padding: 5px;
            }
        }
    }
}

.message {
    margin-bottom: $grid-gutter-width;
    padding: $grid-gutter-width;
    font-size: 13px;
    display: none;

    &.sm-absolute {
        @include media-breakpoint-up(sm) {
            position: absolute;
        }
    }

    &.visible {
        display: block;
    }

    .message-title {
        display: flex;
        font-weight: 700;
        .message-title-icon {
            margin-right: 5px;
        }
    }

    p {
        margin: 0.5rem 0 0 0.5rem;
    }
}

.form-group-year label {
    display: none;
    @include media-breakpoint-up(sm) {
        display: inline-block;
    }
}

.form-help {
    color: $gray-700;
    margin-top: -5px;
    font-size: 13px;
}
.form-help-2 {
    color: $gray-700;
    margin-top: -5px;
    font-size: 13px;
    display: block;

    @include media-breakpoint-up(sm) {
        display: none;
    }
}

.form-help-3 {
    display: none;
    @include media-breakpoint-up(sm) {
        display: block;
        color: $gray-700;
        margin-top: -5px;
        font-size: 13px;
    }
}

.input-with-validation {
    display: flex;
    align-items: center;
    input {
        flex: 1;
        position: relative;
        &.input-rad {
            background-image: none;
        }
    }
    .input-validation {
        width: 20px;
        justify-content: center;
        display: flex;
        font-size: 14px;
        .fa-check {
            width: 10px;
            @extend .text-success;
        }
        .fa-times {
            width: 12px;
            @extend .text-danger;
        }
    }
    .input-validation-tel {
        position: absolute;
        right: 4px;
    }
    .input-validation-restitution-tel {
        position: absolute;
        right: -10px;
        @include media-breakpoint-down(sm) {
            right: -12px;
        }
    }
    .input-validation-tel-rad {
        position: absolute;
        right: -20px;
        @include media-breakpoint-down(sm) {
            right: -12px;
        }
    }
}

.cta-block {
    margin: 2rem 0;
    text-align: center;
    @include media-breakpoint-up(md) {
        .btn {
            min-width: 325px;
        }
    }
}

.dealers-list {
    margin-top: 10px;
    max-width: map-get($grid-breakpoints, 'md');
    .button-dealer {
        display: flex;
        border: 2px solid $gray-400;
        padding: 1rem;
        margin-bottom: 0.5rem;
        line-height: 1.5;
        position: relative;
        min-height: 125px;
        align-items: center;
        .button-dealer-name {
            font-size: 11px;
        }
        .button-dealer-icon {
            width: 25px;
            min-width: 25px;
            max-width: 25px;
            align-self: center;
        }
        &.hidden-dealer {
            display: none;
        }
        .button-dealer-avatar {
            border-radius: 50%;
            text-align: center;
            border: 1px solid $body-color;
            display: inline-flex;
            margin-right: 10px;
            img {
                width: 68px;
                height: 68px;
                border-radius: 50%;
            }
        }
        .button-dealer-distance {
            position: absolute;
            right: 10px;
            top: 10px;
        }
        .button-dealer-expert {
            font-weight: bold;
            font-size: 16px;
        }
        .button-dealer-review {
            font-size: 9px;
            margin: 5px 0;
            div {
                display: inline-block;
            }
        }
    }

    &.show-all {
        .button-dealer.hidden-dealer {
            display: flex;
        }
    }
}

.google-map {
    width: 100%;
    border: 1px solid;
    height: 215px;
    margin-bottom: 1rem;

    @include media-breakpoint-down(sm) {
        margin-top: 5px;
    }
}

ul,
ol {
    li {
        padding-bottom: 0.5rem;
    }
}

ol {
    list-style: none;
    counter-reset: bold-counter;
    li {
        counter-increment: bold-counter;
    }
    li::before {
        content: counter(bold-counter) '. ';
        font-weight: 700;
    }
}

.page {
    padding: 0;
    margin: 0;
}

.page-index {
    .cta-block {
        .btn {
            width: 100%;
            @include media-breakpoint-up(sm) {
                width: auto;
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }
    .progress {
        min-width: 240px;
    }
    .form-cgu {
        font-size: 13px;
    }
}

.page-confirmation,
.modal-body {
    h3 {
        font-size: 16px !important;
        font-weight: bold;
    }
    p {
        font-size: 13px;
    }

    .page-confirmation-reference {
        font-size: 16px;
    }
    .appointment-confirmation-call {
        @extend .p-3;
        font-size: 13px;
        display: flex;
        background-color: #262f37;
        color: white;

        margin-bottom: 20px;
        .appointment-confirmation-icon {
            @extend .pr-2;
        }
    }

    .appointment-confirmation-dealer {
        display: flex;
        margin-bottom: 17px;
        .appointment-confirmation-dealer-avatar {
            border-radius: 50%;
            border: 1px solid $body-color;
            margin-right: 10px;

            img {
                border-radius: 50%;
                width: 68px;
                height: 68px;
            }
        }
    }

    .appointment-confirmation-documents {
        @extend .p-4;
        background-color: #f1f1f1;
        h3 {
            margin-bottom: 1rem;
        }
    }

    .form-group-cancellationReason {
        label {
            font-weight: 400;
            margin-bottom: 10px;
        }
    }
    .form-group-cancellationReasonDescription {
        margin-bottom: 0;

        textarea {
            height: 120px;
        }
        @include media-breakpoint-up(sm) {
            width: 450px;
        }
    }
    .form-control {
        @include media-breakpoint-up(sm) {
            width: 430px;
        }
    }
}

.page-record {
    .valuation {
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .btn {
        margin-left: auto;
        margin-right: auto;
        max-width: map-get($grid-breakpoints, 'sm');
    }

    .background-private-sell,
    .background-direct-sell {
        padding: $grid-gutter-width;
        height: 100% !important;
        display: flex;
        flex-direction: column;

        a {
            color: $gray-600;
        }
    }
}

.page-error {
    &-title {
        color: $primary;
    }
    p {
        font-size: 13px;
    }
    .cta-block {
        text-align: center;
        button {
            width: 300px;
            background-color: $primary;
            @include media-breakpoint-up(sm) {
                max-width: map-get($grid-breakpoints, 'sm');
            }
        }
    }
}

.page-unsubscribe {
    .form-unsubscribe {
        max-width: map-get($grid-breakpoints, 'sm');
    }

    .unsubscribe-return {
        @extend .p-3;

        max-width: map-get($grid-breakpoints, 'md');
        font-size: 13px;
        &.valid {
            background-color: #ebebeb;
        }
        &.error {
            @extend .text-light;
            @extend .bg-danger;
        }
        display: flex;
        .unsubscribe-return-message {
            @extend .pl-2;
        }
    }
    .cta-block {
        text-align: center;

        @include media-breakpoint-up(md) {
            text-align: left;
        }
        button {
            width: 250px;
            @include media-breakpoint-up(sm) {
                max-width: map-get($grid-breakpoints, 'sm');
            }
        }
    }
    p {
        font-size: 13px;
    }
}

.page-archive {
    // margin: 15px;
    &-file-number {
        font-size: 16px;
        margin-bottom: 15px;
    }
    p {
        font-size: 13px;
    }
    .cta-block {
        margin: 1rem 0 1rem;
        .btn {
            margin: 0;
        }
    }
}

label {
    font-size: $font-size-base;
    font-weight: 700;
    color: $body-color;
    transition: $input-transition;
    svg.fa-question-circle {
        width: 19px !important;
        height: 19px !important;
    }
}

.input-group-append,
.input-group-text {
    background-color: white;
    color: $gray-500;
    border-radius: 0px;
}

.form-group {
    .text-danger {
        margin: 5px 0;
    }

    &:focus-within {
        label {
            color: $input-active-color;
        }
    }
}

.feature-group {
    background-color: #262f37;
    color: white;
    padding: 2rem;
    text-align: center;
    margin: 0px;
    .feature {
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: top;
        padding-top: 50px;
        flex: 1;
        text-align: center;
        max-width: 170px;
        margin: auto;
        min-height: 95px;
    }
    .feature-text {
        font-size: 13px;
    }
}

.feature-details {
    text-align: center;
    margin: 0.5rem auto;
    font-size: 0.8rem;
    max-width: 200px;
}
.valuation-box {
    padding: $grid-gutter-width;
}

.valuation-description {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 13px;
    @include media-breakpoint-up(sm) {
        width: 478px;
    }
}
.valuation-reference {
    font-size: 16px;
}

.accordion-info {
    font-size: 13px;
    padding: 0 0 13px 0;
    .accordion-info-title {
        margin-bottom: 0;
    }

    .accordion-info-title-icon {
        font-size: 17px;
    }
    .accordion-info-details {
        text-align: justify;
        margin-top: $halfGutter;
    }
}

.progress-bar-section {
    position: sticky;
    margin-bottom: 2.5rem;
}

.progress-steps {
    margin: $grid-gutter-width 0;
    .progress-step-content {
        display: flex;
        align-items: center;
        justify-content: bottom;
        .step {
            flex: 1;
            margin-top: auto;
            text-align: center;

            .label {
                font-size: 10px;
                width: 50px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 5px;
                color: black;
            }
            .number {
                font-size: 0.8rem;
                border-radius: 100%;
                border: 1px solid black;
                color: black;
                background-color: white;
                display: inline-block;
                height: 20px;
                width: 20px;
                text-align: center;
                line-height: 20px;
                &.completed {
                    color: white;
                    background-color: black;
                    svg {
                        width: 10px;
                        height: 10px;
                        position: relative;
                        top: -1px;
                    }
                }
            }
        }
    }
    .progress {
        margin: -11px 13% 0 13%;
        position: relative;
        z-index: -1;
    }
}

.motivation {
    background-color: $gray-100;
    padding: 1.5rem 1rem;
    margin: 1rem 0rem;
    border-bottom: 1px solid $gray-500;
    text-align: center;
    .motivation-title {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
    .progress-steps {
        max-width: 700px;
        position: relative;
        z-index: 1;
        margin: 2rem auto 2.5rem auto;
    }
}

.alert-covid {
    background-color: $dangerOpacity;
    padding: 0.8rem 0.8rem 0 0.8rem;
    margin: 1rem;
    max-width: map-get($grid-breakpoints, 'md');
}

.appointment-resume {
    padding: $grid-gutter-width;
    display: flex;
    margin-bottom: $grid-gutter-width;
    border: 1px solid #a3a3a3;
    .appointment-resume-text {
        padding-left: $grid-gutter-width;
        div {
            margin: 0;
            font-weight: normal;
            font-size: 16px;
        }
    }
}

.vehicle-informations {
    line-height: 1.75rem;
    border: 1px solid #a3a3a3;
    padding: 2rem;
}

.footnote {
    text-align: justify;
    font-size: 11px;
    color: $gray-700;
}

.modal {
    .modal-header button.close {
        padding: 0;
        margin: -8px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        opacity: 1;
        font-family: arial;
        span {
            width: 19px;
            font-size: 18px;
            line-height: 20px;
            display: block;
        }
    }
    .btn {
        display: block;
        width: 100%;
        max-width: 260px;
    }
}

.loading-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
}

.form-section-title {
    font-size: 0.8rem;
    padding-bottom: 6px;
    margin: 1rem 0;
    text-transform: uppercase;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.btn-group {
    .btn {
        font-weight: 400;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.custom-checkbox {
    .custom-control-input {
        margin-top: 0rem;
    }
    label {
        padding-top: 4px;
        font-weight: 400;
        font-size: 0.8rem;
    }
}

.numbered-title {
    @extend h2;
    margin-bottom: 1.5rem;
}

.choose-date-background {
    margin: 30px 0;
    padding: 20px 0;
    background-color: #f1f1f1;
}

.form-group-registration {
    @include media-breakpoint-up(lg) {
        margin-top: 50px;
    }
    .btn {
        padding-left: 5px;
        padding-right: 5px;
        height: 50px;
        width: 6.125em;
    }
    label {
        font-weight: bold;
    }
}

.registration-or-make-logo {
    width: 100%;
    text-align: center;
    font-size: 21px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 0.1em;
    margin: 10px 0 20px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    span {
        background: $body-bg;
        padding: 0 10px;
    }

    @include media-breakpoint-up(lg) {
        border: 0;
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;
        width: 50px;
        border-bottom: 0 !important;

        span {
            text-align: center;
            display: block;
            background-color: $body-bg;
            width: 50px;
            height: 40px;
            line-height: 40px;
        }
        span:before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 25px;
            border-left-width: 1px;
            border-left-style: solid;
            transform: translate(-50%);
        }
    }

    @include media-breakpoint-down(md) {
        margin-top: 20px;
        margin-bottom: 30px;
    }
}

.form-group-mileage {
    .form-control {
        border-right: 0;
    }
    .input-group-text {
        font-weight: 700;
        background-color: transparent;
    }
}

.page-valuation,
.page-unsubscribe {
    .input-group {
        select,
        input {
            border-right: 0;
        }
    }
}

@include media-breakpoint-up(sm) {
    .form-group-month label {
        width: 200%;
    }
}

.mobile-help {
    display: inline;
    @include media-breakpoint-up(sm) {
        display: none;
    }
}

.page.page-cookies {
    margin-bottom: 3rem;
    h1 {
        font-weight: 700;
    }
    h2 {
        border-top: 1px solid $body-color;
        border-bottom: 1px solid $body-color;
        font-weight: 700;
        text-align: center;
        font-size: 1.5em;
        margin: 2em 0 1em 0;
        padding: 0.15em;
    }
}

.page.page-general-conditions {
    margin-bottom: 3rem;
    h1 {
        font-weight: 700;
    }
    h2 {
        font-weight: 700;
        font-size: 1.5em;
        margin: 2em 0 1em 0;
    }
    h3,
    h4,
    h5 {
        font-weight: 700;
        font-size: 1.2em;
        margin: 1em 0;
    }
}

.form-group-privacy {
    .form-check-input {
        margin-top: 0.15rem;
    }
    label {
        font-weight: normal;
    }
    &.invalid {
        label {
            color: $danger;
            border-bottom: 2px solid $danger;
        }
    }
}

textarea,
textarea::placeholder {
    font-size: 0.85rem;
}

.form-group-notRollingDescription textarea {
    height: 5.2em;
}

.form-control {
    height: 50px;
    border-radius: 0px;
    &:disabled {
        background-color: $light-gray;
    }
}

.form-title {
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 25px;
}

.license-plate-popup {
    .modal-header {
        padding-bottom: 0;
    }
    .modal-title {
        font-weight: bold;
        font-size: 21px;
    }

    div.license-plate-title {
        border-bottom: 1px solid $gray-500;
        padding-bottom: 10px;
        font-size: 13px;
        margin: 0;
    }

    div.license-plate-body {
        padding: 10px 0;
        font-size: 13px;
        border-bottom: 1px solid $gray-500;
    }
}

.page-loader {
    min-height: 400px;
    text-align: center;

    p {
        text-align: justify;
        font-size: 13px;
    }
    .loading-page-argument-1,
    .loading-page-argument-2,
    .loading-page-argument-3 {
        display: none;

        &.show {
            display: flex;
        }
    }
}

.spinner-loader {
    min-height: 400px;
}

.page-loader-redirection {
    min-height: 400px;
    text-align: center;
    display: flex;
    align-items: center;

    p {
        text-align: justify;
        font-size: 13px;
    }
}

.bloc-hbs {
    max-width: map-get($grid-breakpoints, 'md');
    border: 1px solid $gray-300;
    padding: 1rem;
    margin-bottom: 0.5rem;
    line-height: 1.5;
    .text-new {
        color: $primary;
    }
    span {
        font-size: 16px;
        font-weight: bold;
    }
    p {
        font-size: 13px;
    }
    .cta-block {
        width: 235px;
        margin: 0;
    }
    .bloc {
        @include media-breakpoint-down(xs) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
#appointment_page_bloc_1,
#appointment_page_bloc_2,
#appointment_page_bloc_3 {
    margin: 20px;

    .available_slots_remaining {
        margin-top: 8px;
        font-weight: bold;
        font-size: 13px;
    }
}

#appointment_page_bloc_2 {
    .hoursInput {
        @include media-breakpoint-down(xs) {
            margin-top: 20px;
        }
    }
}

.form-group-phone {
    input {
        max-width: calc(100% - 52px);
    }
}

.option-workflow {
    .btn {
        @include media-breakpoint-down(xs) {
            width: 280px;
            height: 54px;
        }
    }
    .or {
        margin-bottom: 0;
        font-size: 21px;
        font-weight: 700;
        @include media-breakpoint-down(xs) {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}
.service-section {
    background-color: #ebebeb;
    padding: 20px;
    margin-top: 30px;

    &-title {
        text-align: center;
    }
    &-explanation {
        text-align: center;
        font-size: 13px;
    }
    .hbs-phone-bloc {
        display: block;
        @include media-breakpoint-up(sm) {
            display: flex;
        }
        .input-group {
            margin-right: 10px;
        }
    }
}

.hbs-page-service {
    font-size: 13px;
    padding: 5px;
    margin-top: 15px;

    &-number {
        font-size: 32px;
        font-weight: bold;
        color: $primary;
    }
    .service-explanation {
        font-size: 13px;
        font-weight: bold;
    }
    .row {
        display: flex;
        justify-content: center;
    }
    img {
        width: 220px;
        height: 445px;
    }
}

.page-restitution {
    .restitution-phone-input {
        align-items: center;
        input {
            background-image: none;
            border-right: none;
            @include media-breakpoint-down(lg) {
                height: 57px;
            }
        }
        .btn {
            height: 57px;
            max-width: 450px !important;
            margin-left: 15px;
            margin-top: 20px;

            @include media-breakpoint-down(xs) {
                margin-top: 10px;
                max-width: 100% !important;
                margin-left: 0;
            }
        }
    }

    .feature {
        font-weight: 300 !important;
    }
}

.page-confirmation-hbs {
    .hbs-confirmation-title {
        margin-top: 1rem;
        @include media-breakpoint-up(lg) {
            text-align: center;
        }
    }
    .hbs-confirmation-body {
        margin-top: 37px;
        @include media-breakpoint-up(lg) {
            justify-content: center;
        }
    }

    img {
        width: 180px;
        height: 238px;
        @include media-breakpoint-down(sm) {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .hbs-confirmation-description {
        max-width: 350px;

        @include media-breakpoint-up(sm) {
            margin-left: 20px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 20px;
        }

        .hbs-confirmation-number {
            font-size: 32px;
            font-weight: bold;
            color: $primary;
        }

        .hbs-confirmation-detail {
            font-size: 9px;
            @include media-breakpoint-down(xs) {
                font-size: 13px;
            }
        }
        p {
            font-size: 13px;
            @include media-breakpoint-down(xs) {
                font-size: 15px;
            }
        }
    }
}

.form-group-purchaseProject {
    @include media-breakpoint-down(sm) {
        .btn {
            height: 70px;
        }
    }
}

.appointment-confirmation-popup {
    @include media-breakpoint-down(sm) {
        margin-top: 170px;
    }
}
