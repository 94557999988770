.sprite-etoile-bg {
    background-image: url('./img/sprite.svg');
    background-repeat: no-repeat;
    margin-right: 5px;
}

.sprite-etoile-0-dims,
.sprite-etoile-0_5-dims,
.sprite-etoile-1-dims,
.sprite-etoile-1_5-dims,
.sprite-etoile-2-dims,
.sprite-etoile-2_5-dims,
.sprite-etoile-3-dims,
.sprite-etoile-3_5-dims,
.sprite-etoile-4-dims,
.sprite-etoile-4_5-dims,
.sprite-etoile-5-dims {
    width: 82px;
    height: 14px;
}

.sprite-etoile-0 {
    background-position: 0 0;
}
.sprite-etoile-0_5 {
    background-position: 0 -14px;
}
.sprite-etoile-1 {
    background-position: 0 -28px;
}
.sprite-etoile-1_5 {
    background-position: 0 -42px;
}
.sprite-etoile-2 {
    background-position: 0 -56px;
}
.sprite-etoile-2_5 {
    background-position: 0 -70px;
}
.sprite-etoile-3 {
    background-position: 0 -84px;
}
.sprite-etoile-3_5 {
    background-position: 0 -98px;
}
.sprite-etoile-4 {
    background-position: 0 -112px;
}
.sprite-etoile-4_5 {
    background-position: 0 -126px;
}
.sprite-etoile-5 {
    background-position: 0 -140px;
}
